import React from "react";
import "./Airdrop.css";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";

export default function Airdrop({ claim }) {
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  return (
    <div>
      <div className="w-full px-3 md:px-10 pt-16 pb-10 mt-5" data-v-c42fc425="">
        {/*[*/}
        <div className="w-full flex flex-col justify-center items-center px-5 md:px-15 py-15 gap-10">
          <h1 className="text-white text-5xl font-bold text-center">
            Season1 Airdrop
          </h1>
          <div className="flex flex-col gap-2 justify-center items-center">
            <img src="https://static.nfprompt.io/website/nfp/bg/airdrop_bg.png" />
            <p className="text-white text-2xl font-bold">0 $NFP </p>
          </div>
          {/**/}
          <section className="flex flex-col items-center gap-10">
            {/**/}
            {/**/}
            {address == undefined ? (
              <>
                <button
                  className="ant-btn main-btn login_btn"
                  onClick={() =>
                    address
                      ? chain?.id == chains[0]?.id
                        ? open()
                        : switchNetwork?.(chains[0]?.id)
                      : open()
                  }
                  type="button"
                >
                  {/**/}
                  <span>
                    {address ? (
                      chain?.id == chains[0]?.id ||
                      chain?.id == chains[1]?.id ? (
                        address ? (
                          <>Claim</>
                        ) : (
                          "Sign In"
                        )
                      ) : (
                        "Switch NewWork"
                      )
                    ) : (
                      "Sign In"
                    )}
                  </span>
                </button>
              </>
            ) : (
              <>
                <button  className="ant-btn main-btn login_btn" onClick={claim}>Claim</button>
              </>
            )}
          </section>
          <section className="text-white text-base leading-11">
            <h3 className="text-white text-2xl font-bold mb-5">Tips:</h3>
            <p>
              {" "}
              1. To ensure a smooth process, the community is able to check the
              Claim Page 30 minutes before Binance lists $NFP at 2023-12-27
              10:00 (UTC), and all eligible users can claim their $NFP airdrop
              20 minutes before the launch.{" "}
            </p>
            <p>
              {" "}
              2. The airdrop claiming window lasts for one calendar month from
              2023-12-27, 09:40 (UTC), until 2023-01-27, 09:40 (UTC).{" "}
            </p>
            <p>
              {" "}
              3. 10% of the total $NFP supply will be used as the initial
              airdrop for NFPrompt users.{" "}
            </p>
            <p>
              {" "}
              4.Web2 login accounts, and accounts that violate the community
              guidelines or are banned, are not eligible to claim the $NFP
              airdrop.{" "}
            </p>
            <p>
              {" "}
              5. As Season 2 kicks off, $cNFP obtained from the first season,
              before 2023-12-17, 00:00 (UTC) will be reset. This ensures a fair
              starting line for all participants in an exciting and fair Season
              2.{" "}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
